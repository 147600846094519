import React from 'react'

import Flag from '../../../components/Flag/Flag';

import styles from './Flag1.module.scss';
import classNames from 'classnames';

function Flag1({ startGsap, slug }) {
  return (
    <Flag startGsap={startGsap} className={classNames(styles.Flag1, { [styles.startGsap]: startGsap })}>
      <img src={"/assets/text/" + slug + ".jpg"} />
      {/* <img src={"/assets/lips.jpg"} /> */}
    </Flag>
  )
}

export default React.memo(Flag1)
