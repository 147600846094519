import React from 'react'

import styles from './RedCircle.module.scss';
import classNames from 'classnames';

function RedCircle({ className, type = 'pink' }) {
  return (
    <div className={classNames(styles.RedCircle_container, styles.RedCircle, className)}>
      <div className={classNames(styles.RedCircle_circle, styles.RedCircle_pink, { [styles.active]: type === 'pink/' })}></div>
      <div className={classNames(styles.RedCircle_circle, styles.RedCircle_borus, { [styles.active]: type === 'brows/' })}></div>
      <div className={classNames(styles.RedCircle_circle, styles.RedCircle_black, { [styles.active]: type === 'black/' })}></div>
      {/* <div className={classNames(styles.RedCircle, styles.RedCircle_red, {[styles.active]: type === 'red'})}></div> */}
      {/* <img className={styles.RedCircle} src="/assets/red-circle.png" /> */}
    </div>
  )
}

export default React.memo(RedCircle);
