import React from 'react';

import styles from './ScrollButton.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

function ScrollButton() {
  const activeScreen = useSelector(state => state.activeScreen);
  const loaded = useSelector(state => state.loaded)

  if (loaded){
    return (
      <div className={classNames(styles.ScrollButton, { [styles.ScrollButton_center]: activeScreen === 1 }, { [styles.ScrollButton_rotate]: activeScreen === 6 })}>
        <div></div>
      </div>
    )
  }

  return (
    <></>
  )
}

export default React.memo(ScrollButton);
