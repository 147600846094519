import React, { useCallback, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { setFirstAnimation } from '../../store/slice';

import styles from './Preloader.module.scss';
import BurgerStyles from '../Burger/Burger.module.scss';
import RedCircleStyles from '../RedCircle/RedCircle.module.scss';
import HeaderStyles from '../Header/Header.module.scss';
import Logo from './../Logo/Logo';


let gsapT = gsap.timeline();

function Preloader(props) {
  const { onStartGsap } = props;
  const progressSeq = useSelector(state => state.progressSeq);
  const dispatch = useDispatch();
  const [startPreloader, setStartPreloader] = useState(false);
  const startFirstAnimation = useCallback(() => {
    dispatch(setFirstAnimation());
  }, [])

  // console.log('onFinish:', onFinish);

  useEffect(() => {
    const isMobile = window.innerWidth < 1100;

    setTimeout(() => {

      if (!isMobile) {
        gsap.set('.' + BurgerStyles.Burger, { backgroundColor: 'rgba(0, 0, 0, 0.6)' })
      }

      gsap.set('.' + BurgerStyles.Burger, { color: '#fff', x: -85 })
      gsap.set('.' + HeaderStyles.Header, { color: '#000', opacity: 0 })
      gsap.set('.' + RedCircleStyles.RedCircle, { opacity: 0 });
      // const scaleCoef = window.innerWidth / 1920;

      gsap.set('.' + styles.preload_svg, { ...(isMobile ? { width: '60vw' } : { width: '30vw' }) })
      // .call(() => {

      const paths = document.querySelectorAll('.' + styles.preload_svg + ' path');

      for (let path of paths) {
        const length = Math.ceil(path.getTotalLength());

        path.style.strokeDashoffset = -length;
        path.style.strokeDasharray = length + 'px, ' + length + 'px';
        path.style.opacity = 1;
      }

      setStartPreloader(true);
    }, 500);

  }, []);

  useEffect(() => {
    if (startPreloader) {
      const paths = document.querySelectorAll('.' + styles.preload_svg + ' path');

      if (progressSeq < 1) {
        for (let path of paths) {
          const length = Math.ceil(path.getTotalLength());
          gsapT.to(path, { strokeDasharray: length + `px, ${length - progressSeq * length}px`, duration: progressSeq * 2 }, 'parallel');
        }
      } else {
        const isMobile = window.innerWidth < 1100;

        gsapT.kill();
        gsapT = gsap.timeline();

        for (let path of paths) {
          const length = Math.ceil(path.getTotalLength());
          const match = path.style.strokeDasharray.match(/\d+px,\s(.+)px/);
          if (match) {
            const offset = Number(match[1]);
            gsapT.to(path, { strokeDasharray: length + `px, 0px`, duration: 2 * offset / length }, 'parallel')
          } else {
            gsapT.to(path, { strokeDasharray: length + `px, 0px`, duration: 0.5 }, 'parallel')
          }
        }

        gsapT.to('.' + styles.preload_svg + ' path', { fill: '#fff', duration: 1 })
          .call(startFirstAnimation)
          .to('.' + styles.preload_svg, { /* scale: scaleCoef * (isMobile ? 18 : 8) */...(isMobile ? { width: '80vw' } : { width: '50vw' }), duration: 1, ease: 'power3.itOut' })
          .call(() => {
            document.body.classList.add('loaded');
          })
          .to('.' + BurgerStyles.Burger, { x: 0 }, 'burger')
          .fromTo('.' + HeaderStyles.Header, { opacity: 0, position: 'fixed', color: '#fff' }, { opacity: 1, position: 'fixed', color: '#fff' }, 'burger')

        if (isMobile) {
          gsapT.to('.' + BurgerStyles.Burger_progress, { left: '100vw' }, 'burger');
        }

        gsapT.call(() => {
          onStartGsap(true);
        });
      }

    }
  }, [progressSeq, startPreloader]);

  return (
    <div className={styles.Preloader}>
      {/* {typeof window !== 'undefined' && <>{window.innerWidth / 1920}</>} */}
      <Logo className={styles.preload_svg} />
    </div>
  )
}


export default React.memo(Preloader);
