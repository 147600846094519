import React from 'react'
import { isBrowser } from 'react-device-detect';

import dynamic from 'next/dynamic'
import styles from './BigText.module.scss';

function BigText() {
  // const loaded = useSelector(state => state.loaded);

  // useEffect(() => {
  //     if (loaded) {
  //         // gsapTimeline.target4.to('.BlackBg', { opacity: 1 }, 'target-4')
  //         // gsapTimeline.target6.to('.BlackBg', { opacity: 0 }, 'target-6')
  //     }
  // }, [loaded])

  if (isBrowser) {
    return (
      <div className={styles.BigText}>
        <div className="text-target-2-7-text">colors</div>
        <div className="text-target-3-4-text">pigments</div>
      </div>
    )
  }

  return null
}


const DynamicComponentWithNoSSR = dynamic(() => Promise.resolve(BigText), {
  ssr: false
})

DynamicComponentWithNoSSR.displayName = 'DynamicComponentWithNoSSR';

const ExportBigText = React.memo(() => <DynamicComponentWithNoSSR />);
ExportBigText.displayName = 'BigText';

export default ExportBigText;