const names = [
  'lips',
  'brows',
  'eyes'
];

export default names

const images = {
  pink: {
    center: 'pink/sec-center',
    left: 'brows/sec-left',
    right: 'black/sec-right',
  },
  right: {
    center: 'brows/sec-center',
    left: 'black/sec-left',
    right: 'pink/sec-right',
  },
  left: {
    center: 'black/sec-center',
    left: 'pink/sec-left',
    right: 'brows/sec-right',
  },
}

export { images }