import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import names from '../swiper-names';
import styles from './SecondSwiper.module.scss';
import { Navigation } from 'swiper';
import classNames from 'classnames';
import Link from 'next/link';
import Image from "next/image";
import { Tab, Tabs } from "@mui/material";
import { styled } from '@mui/material/styles';

import { setPageLoading } from '../../../store/slice'
import { useDispatch } from 'react-redux';

const CustomTabs = styled(Tabs)({
  overflow: 'visible',
  // width: '100%',
  '& .MuiTabs-scroller': {
    overflow: 'visible !important',
  },
  '& .MuiTabs-indicator': {
    marginLeft: '5px',
    marginRight: '2px',
    marginBottom: '14px',
    maxWidth: '88px',
    // height: '115%',
    backgroundColor: 'transparent',
    border: '1px solid #a31123',
    // borderRadius: '50%',
    // transform: 'rotate(-6.33deg) translateY(-7px)',
    
  },
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontSize: 23,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  fontFamily: 'Neutral Face',
  letterSpacing: 1,
  color: 'rgba(0, 0, 0, 1)',
  padding: '0px 10px',
  lineHeight: 1,
  minHeight: 30,
  '&.Mui-selected': {
    color: '#a31123',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

/**
 * 
 * @param {{items: {id: number, images: string[], image_color}[]}} param0 
 * @returns 
 */
function SecondSwiper({ items = [], changeDir, activeIndex }) {
  const [valueTab, setValueTab] = React.useState(activeIndex);

  const dispatch = useDispatch()

  const handleChange = (e, newValue) => {
    e.stopPropagation();
    setValueTab(newValue);
    changeDir(['pink/', 'brows/', 'black/'][newValue]);
  };

  useEffect(() => {
    setValueTab(activeIndex);
  }, [activeIndex]);

  const flipHorizontally = {
    transform: 'scaleX(-1)',
  }

  const handleClick = () => {
    dispatch(setPageLoading(true))
  }


  return (
    <div className={styles.SecondSwiper}>
      <div className={styles.SecondSwiper_wrapper_tabs}>
        <CustomTabs
          centered
          value={valueTab}
          onChange={handleChange} 
          variant="fullWidth"
        >
          <CustomTab
            component="h2"
            label="ГУБЫ"
          />
          <CustomTab
            component="h2"
            label="БРОВИ"
          />
          <CustomTab
            component="h2"
            label="ГЛАЗА"
          />
        </CustomTabs>
      </div>
      <Swiper
        className={styles.SecondSwiper_Swiper}
        breakpoints={{
          320: { slidesPerView: 1, },
          1100: { slidesPerView: 3.3, }
        }}
        modules={[Navigation]}
        speed={700}
        navigation={
          {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        }
        centeredSlides={true}
        loop={true}
      >
        {
          items.filter(item => item.thumbnails_color).map(item => {
            return (
              <SwiperSlide key={item.id} className={styles.SecondSwiper_Slide}>
                <Link href={`/shop/${item.category.slug}/${item.slug}`} >
                  <a onClick={handleClick}>
                    <Image src={item.thumbnails[0]} className={styles.SecondSwiper_Slide_main} layout="fill" objectFit="fill" />
                    <Image src={item.thumbnails_color} className={styles.SecondSwiper_Slide_swk} alt="" layout='fill' objectFit="fill" />
                  </a>
                </Link>


                <Link href={`/shop/${item.category.slug}/${item.slug}`} >
                  <div onClick={handleClick} className={styles.SecondSwiper_Slide_footer}>
                    <div className={styles.text}>{item.info}</div>
                    <div className={styles.h3}>{item.title}</div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          })
        }
      </Swiper>

      <div className={styles.SecondSwiper_navigation}>
        <button type="button" className={classNames(styles.SecondSwiper_navigation_button, "swiper-button-prev")}>
          <svg width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
          </svg>
        </button>
        <button type="button" className={classNames(styles.SecondSwiper_navigation_button, "swiper-button-next")}>
          <svg style={flipHorizontally} width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default React.memo(SecondSwiper)
