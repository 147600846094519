import React from 'react';

import styles from './BlackBg.module.scss';

function BlackBg({ text, loaded, activeScreen }) {
  return (
    <div className={styles.BlackBg}>
      <div className="bg">
        {text && loaded && activeScreen === 1 &&
          <h1>{text}</h1>
        }
      </div>
    </div>
  )
}

export default React.memo(BlackBg)
