import React, { useState } from 'react';

import styles from './Target2Text.module.scss';
import { useTranslation } from '../../utils/LocaleProvider';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Controller } from 'swiper';
import classNames from 'classnames';

function Target2Text({ images, changeDir, activeIndex }) {
  const { t } = useTranslation();

  // const handleChange = sw => {
  //   console.log(sw.activeIndex);
  //   changeDir(['pink/', 'brows/', 'black/'][sw.activeIndex % 3])
  // }

  const flipHorizontally = {
    transform: 'scaleX(-1)',
  }

  return (
    <div className={styles.Target2Text}>
      <Swiper
        className={styles.Target2Text_Swiper}
        modules={[Navigation, Controller]}
        initialSlide={activeIndex}
        slidesPerView={1}
        centeredSlides={true}
        speed={500}
        navigation={
          {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        }
        // onSlideChange={handleChange}
        loop={true}
      >
        {
          Object.keys(images).map((color, index) => (
            <SwiperSlide key={index} className={styles.Target2Text_Slide}>
              <div className={styles.image}>
                <Image priority={true} {...images[color]} />
              </div>
              <div className="h2">{t(`main.${color}_header`, 'Палитра пигментов')}</div>
              <p>
                {t(`main.${color}_text`, 'В палитре пигментов для губ вы найдете решение для любой задачи – создать мягкий вуалевый оттенок перекрыть неудачный старый перманентный макияж или вернуть губам четкость контура и наполнить их цветом.')}
              </p>
            </SwiperSlide>
          ))
        }
      </Swiper>

      <div className={styles.Target2Text_navigation}>
        <button type="button" className={classNames(styles.Target2Text_navigation_button, "swiper-button-prev")}>
          <svg width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
          </svg>
        </button>
        <button type="button" className={classNames(styles.Target2Text_navigation_button, "swiper-button-next")}>
          <svg style={flipHorizontally} width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default React.memo(Target2Text)
