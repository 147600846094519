import { useSwipeable } from "react-swipeable";
import React from 'react';

const Swipeable = ({ onSwipedRight, onSwipedUp, onSwipedDown, onSwipedLeft, className, children }) => {
  const handlers = useSwipeable({
    onSwipedRight: onSwipedRight || (() => { }),
    onSwipedUp: onSwipedUp || (() => { }),
    onSwipedDown: onSwipedDown || (() => { }),
    onSwipedLeft: onSwipedLeft || (() => { })
  });

  return (
    <div {...handlers} className={className}>
      {children}
    </div>
  )
}

export default React.memo(Swipeable);
