class ImageLoader {
  /**
   *
   * @param {String} template Путь к изображениям
   */
  constructor(template, data) {
    this.images = {};
    this.loadCount = 0;
    // this.template = template;

    // this.ext = data.ext;
  }


  /**
   *
   * @param {Array<{ start: Number, end: Number, fps: Number, increment: Number }>} images Набор изображений п
   * @param {Function} callback Функция обратного вызова на каждую загруженную фотографию
   * @returns {Promise} Promise load
   */
  load(images, callback = () => { }, onProgress = () => { }) {
    this.template = this.template.replace('{ext}', this.data.ext);

    let loadCount = 0;
    let fullCount = 0;

    return Promise.all(images.map((imageTemp) => {
      if (this.images[imageTemp.dir] && this.images[imageTemp.dir].find(i => i.start === imageTemp.start && i.end === imageTemp.end)) {
        callback();
        return;
      }

      imageTemp.increment = imageTemp.increment || 1;
      const imagesObject = [];

      for (let i = imageTemp.start; i <= imageTemp.end; i += imageTemp.increment) {
        const image = new Image();
        image.src = `${this.template}${i}.${this.data.ext}`.replace('{dir}', imageTemp.dir);
        imagesObject.push(image);
      }

      fullCount += imagesObject.length;
      imageTemp.max = imagesObject.length - 1;
      this.images[imageTemp.dir] = this.images[imageTemp.dir] || [];
      this.images[imageTemp.dir].push({ images: imagesObject, ...imageTemp, loadCount: 0 });

      return Promise.all(
        imagesObject.map(image => {
          return new Promise(resolve => {
            image.onload = () => {
              loadCount++;
              onProgress(Number((loadCount / fullCount).toFixed(2)));
              resolve(true);
            };

            image.onerror = () => {
              loadCount++;
              onProgress(Number((loadCount / fullCount).toFixed(2)));
              resolve(false);
            };
          })
        })
      )
    })).then((result) => {
      this.loaded = true;
      callback();
      return result;
    });
  }
}

export default ImageLoader;