import React, { Component } from 'react';
import requestAnimFrame from './animation';


class Canvas extends Component {
  canvas = React.createRef();
  timerSeq;
  active = 0;
  fps = this.props.fps || 60;
  startFps = this.props.fpsReverseStart || this.props.fps || 60;
  reverse = this.props.reverse;
  imageIndex = this.props.imageIndex;
  target = 0;
  lastTarget = 0;
  lastProgress = 0;
  maxTarget = 5;
  lastTime = Date.now();

  fps; fpsInterval; startTime; now; then; elapsed; animateStart;

  componentDidMount() {
    if (!isNaN(this.props.active)) {
      this.active = this.props.active;
      this.drawIndex(this.props.active);
    }

    if (this.props.start) {
      this.nextSeq();
    }
  }

  get imageLoader() {
    return this.props.imageLoader.images[this.props.dir][this.imageIndex];
  }

  get max() {
    return this.imageLoader?.max ?? 0;
  }

  reset() {
    this.active = 0;
    this.nextSeq();
  }

  animate(callback) {
    if (!this.animateStart) return;
    requestAnimFrame(this.animate.bind(this, callback));

    this.now = Date.now();
    this.elapsed = this.now - this.then;

    if (this.elapsed > this.fpsInterval) {
      this.then = this.now - (this.elapsed % this.fpsInterval);
      callback();
    }
  }

  startAnim(fps, callback) {
  }


  set drawIndexValue(value) {
    requestAnimFrame(() => {
      const nextValue = Number(Number(value).toFixed(0));
      const nextTarget = Math.ceil(this.target);


      if (nextTarget > this.maxTarget) {
        return;
      }

      if (this.lastProgress !== nextValue) {
        if (Math.abs(this.lastProgress - nextValue) > 1) {
          this.lastProgress = nextValue;
          return;
        }

        this.lastProgress = nextValue;
        this.lastTarget = nextTarget;
        const thatTime = Date.now();
        this.lastTime = thatTime;

        this.active = this.lastProgress;
        this.draw(this.lastProgress, this.lastTarget);
      }
    });
  }

  drawIndex(index, target) {
    if (this.props.images) {
      this.clear();
      this.draw(index, target);
    }
  }

  nextSeq(fps = 45, callback = () => { }) {
    this.fpsInterval = 1000 / fps;
    this.then = Date.now();
    this.startTime = this.then;
    this.animateStart = true;

    this.animate(() => {
      this.clear();
      this.draw(this.active);
      this.active++;

      if (this.active > this.max) {
        this.animateStart = false;
        callback();
      }
    });
  }

  prevSeq(fps = 45, callback = () => { }) {
    this.fpsInterval = 1000 / fps;
    this.then = Date.now();
    this.startTime = this.then;
    this.animateStart = true;

    this.animate(() => {
      this.clear();
      this.draw(this.active);
      this.active--;

      if (this.active < 0) {
        this.animateStart = false;
        callback();
      }
    });
  }

  draw(index = this.active, secondTarget = this.imageIndex) {
    this.imageIndex = secondTarget;
    const image = this.imageLoader?.images?.[index];

    if (!image) return;

    if (this.canvas.current && image) {
      const canvas = this.canvas.current;
      // const width = Math.min(window.innerWidth - (375 - 334), 526);
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;

      let leftOffset = 0;
      let topOffset = 0;

      this.canvas.current.getContext('2d').drawImage(
        image,
        leftOffset,
        topOffset,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  }

  clear() {
    if (this.canvas.current) {
      this.canvas.current.getContext('2d').clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
    }
  }

  render() {
    return (
      <canvas id={this.props.id} className={this.props.className} ref={this.canvas} />
    )
  }
}


export default Canvas;