import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';

import styles from '../styles/Home.module.scss'
import dynamic from 'next/dynamic';
import BlackBg from '../components/BlackBg/BlackBg';
import Preloader from '../components/Preloader/Preloader';
import ScrollButton from '../components/ScrollButton/ScrollButton';
import Canvas from '../components/Canvas/Canvas';
import RedCircle from '../components/RedCircle/RedCircle';
import Target2Text from '../components/Target2Text/Target2Text';
import UniqueText from '../components/UniqueText/UniqueText';
import BigText from '../components/BigText/BigText';

import SecondSwiper from '../components/swipers/SecondSwiper/SecondSwiper';
import { useDispatch, useSelector } from 'react-redux';
import support_format_webp from './../utils/webp-support';
import ImageLoader from './../utils/image-loader';
import { setActiveScreen, toggleChangeScreen, setProgressSeq } from '../store/slice';
import Flag1 from '../modules/Home/flag/Flag1';
import FirstSwiper from '../components/swipers/FirstSwiper/FirstSwiper';
import ThirdSwiper from '../components/swipers/ThirdSwiper/ThirdSwiper';
import Swipable from '../modules/Home/Swipable/Swipable';
import CONFIG from '../config';
import fetcher from '../utils/fetcger';
import * as Sentry from '@sentry/nextjs';


import brows from '../components/Target2Text/text/brows.webp'
import eyes from '../components/Target2Text/text/eyes.webp'
import lips from '../components/Target2Text/text/lips.webp'

const images = {
  lips,
  brows,
  eyes,
}

const heading = 'профессиональные пигменты'

const Gsap = dynamic(() => import('../modules/Home/gsap/Gsap'), { ssr: false });
const IMAGES_SEC = new ImageLoader();

export default React.memo(function Home({ categories = {} }) {
  const loaded = useSelector(state => state.loaded);
  const activeScreen = useSelector(state => state.activeScreen);
  const isChangeScreen = useSelector(state => state.isChangeScreen);
  const firstAnimation = useSelector(state => state.firstAnimation);
  const openMenu = useSelector(state => state.openMenu);
  const openCart = useSelector(state => state.openCart);

  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [startGsap, setStartGsap] = useState(false);
  const [activeDir, setActiveDir] = useState('pink/');
  const canvasRef = useMemo(() => React.createRef(), []);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const mobile = window.innerWidth < 1100;
    IMAGES_SEC.template = '/assets/sec/' + (mobile ? 'mobile/' : '') + '{ext}/{dir}sec-';
    IMAGES_SEC.data = { ext: support_format_webp() ? 'webp' : 'png' };
  })


  useEffect(() => {
    async function load() {
      try {
        await IMAGES_SEC.load([
          { start: 1, end: 31, fps: 60, dir: 'pink/' },
          { start: 32, end: 61, fps: 60, dir: 'pink/' },
          { start: 31, end: 31, fps: 60, dir: 'brows/' },
          { start: 32, end: 61, fps: 60, dir: 'brows/' },
          { start: 31, end: 31, fps: 60, dir: 'black/' },
          { start: 32, end: 61, fps: 60, dir: 'black/' },
        ], () => { }, progress => dispatch(setProgressSeq(progress)));
      } catch (e) {
        Sentry.captureException(e);
      }

      if (canvasRef.current) {
        canvasRef.current.imageIndex = 0;
        setImagesLoaded(true);
      }
    }

    if (canvasRef.current) {
      document.body.classList.add('hidden');
      load();
    }
  }, [canvasRef.current]);

  async function loadNextAll() {
    await IMAGES_SEC.load([
      { start: 62, end: 91, fps: 60, dir: 'pink/' },
      { start: 62, end: 91, fps: 60, dir: 'brows/' },
      { start: 62, end: 91, fps: 60, dir: 'black/' },
    ]);
  }

  const handleChangeDir = useCallback((dir) => {
    setActiveDir(dir);
    setActiveIndex(['pink/', 'brows/', 'black/'].indexOf(dir))
  }, [])

  useEffect(() => {
    if (activeScreen === 2 && IMAGES_SEC.images['pink/'].length <= 3) {
      loadNextAll();
    }
  }, [activeScreen]);

  useEffect(() => {
    async function loadOther() {
      if (activeScreen > 1 && IMAGES_SEC.images[activeDir].length <= 3) {
        await IMAGES_SEC.load([
          { start: 92, end: 121, fps: 60, dir: activeDir },
          { start: 121, end: 121, fps: 60, dir: activeDir },
          { start: 122, end: 224, fps: 60, dir: activeDir },
        ]);
      }
    }

    if (loaded && imagesLoaded) {
      canvasRef.current.draw();
      loadOther();
    }
  }, [activeDir, activeScreen, loaded, imagesLoaded]);

  // stdout('draw first');

  useEffect(() => {
    async function loadWithoutChange() {
      if (activeScreen > 3 && IMAGES_SEC.images['brows/'].length <= 3) {
        await IMAGES_SEC.load([
          { start: 92, end: 121, fps: 60, dir: 'brows/' },
          { start: 121, end: 121, fps: 60, dir: 'brows/' },
          { start: 122, end: 224, fps: 60, dir: 'brows/' },
        ]);
      }

      if (activeScreen > 4 && IMAGES_SEC.images['black/'].length <= 3) {
        await IMAGES_SEC.load([
          { start: 92, end: 121, fps: 60, dir: 'black/' },
          { start: 121, end: 121, fps: 60, dir: 'black/' },
          { start: 122, end: 224, fps: 60, dir: 'black/' },
        ]);
      }
    }

    loadWithoutChange();
  }, [activeScreen]);

  const handelScroll = useCallback((direction = 1) => {
    if (isChangeScreen) return
    if (loaded && imagesLoaded && activeScreen + direction > 0 && activeScreen + direction < 7) {
      dispatch(toggleChangeScreen());
      dispatch(setActiveScreen(activeScreen + direction));
      // scrollTo('.target-' + (activeScreen + direction), activeScreen);
    }
  }, [activeScreen, loaded, isChangeScreen, imagesLoaded]);

  const windowScrollEvent = useCallback((event) => {
    if (!isChangeScreen && !openMenu && !openCart) {
      if (event.deltaY > 0) {
        handelScroll()
      } else {
        handelScroll(-1)
      }
    }
  }, [loaded, activeScreen, isChangeScreen, openMenu, openCart, imagesLoaded]);

  useEffect(() => {
    if (loaded) {
      window.addEventListener('wheel', windowScrollEvent);
    }

    return () => {
      window.removeEventListener('wheel', windowScrollEvent);
    }
  }, [loaded, activeScreen, isChangeScreen, openMenu, openCart, imagesLoaded]);

  const handelScrollButton = useCallback(() => {
    if (activeScreen === 6) {
      handelScroll(-1)
    } else {
      handelScroll()
    }
  }, [activeScreen, loaded, isChangeScreen, imagesLoaded]);

  function handleSwipeUp() {
    handelScroll(1)
  }

  function handleSwipeDown() {
    handelScroll(-1)
  }

  return (
    <>
      <Head>
        <title>OXWE – Профессиональные пигменты для перманентого макияжа</title>
        <meta name="description" content="Пигменты для мастеров перманентного макияжа любого уровня. 26 основных оттенков для создания идеальных работ. Доставка по всей России."/>
        <meta name="facebook-domain-verification" content="nlmm1p983de8z11702vqsvqzazfqrv" />
      </Head>

      {/* <main className={styles.Home}> */}
      <Swipable onSwipedUp={handleSwipeUp} onSwipedDown={handleSwipeDown} className={styles.Home}>
        <BlackBg text={heading} loaded={loaded} activeScreen={activeScreen}/>
        <Preloader onStartGsap={setStartGsap} />
        <Canvas className={styles.Home_canvas} ref={canvasRef} dir={activeDir} imageLoader={IMAGES_SEC} />
        <ScrollButton />
        <RedCircle type={activeDir} />
        <BigText />
        {<Target2Text images={images} activeIndex={activeIndex} changeDir={handleChangeDir} />}
        {(activeScreen === 5 || activeScreen === 4) && <UniqueText />}
        {(activeScreen === 2 || activeScreen === 1 || activeScreen === 3) && <FirstSwiper activeIndex={activeIndex} changeDir={handleChangeDir} />}
        <SecondSwiper items={categories[activeDir]?.products} activeIndex={activeIndex} changeDir={handleChangeDir} />
        {(activeScreen === 6) && <ThirdSwiper activeIndex={activeIndex} changeDir={handleChangeDir} />}
        {/* <Flag1 slug={categories[activeDir]?.slug} startGsap={true} /> */}

        {firstAnimation && <Gsap canvasRef={canvasRef} dir={activeDir} startGsap={startGsap} />}
      </Swipable>
      {/* </main> */}
    </>
  );
});

export async function getServerSideProps({ locale, req }) {
  //defaults locale to ru
  locale = 'ru'

  const [data, lang] = await Promise.all([
    fetcher('products'),
    fetcher('lang'),
  ]);

  // data.forEach(product => {
  //   product.images = JSON.parse(product.images);
  //   product.image_color = process.env.NEXT_PUBLIC_BACKEND + '/' + product.image_color;
  //   product.images = Object.keys(product.images).reduce((images, key) => {
  //     images[key] = process.env.NEXT_PUBLIC_BACKEND + '/' + product.images[key];
  //     return images;
  //   }, []).filter(image => !!image);
  // });

  const categories = data.reduce((categories, product) => {
    categories[product.category.seq_dir] = categories[product.category.seq_dir] || {
      title: product.category.title?.[locale] ?? '',
      id: product.category.id,
      products: [],
      dir: product.category.seq_dir,
      slug: product.category.slug,
    };

    product.title = product.title?.[locale] ?? '';
    product.info = product.info?.[locale] ?? '';

    categories[product.category.seq_dir].products.push(product);
    return categories;
  }, {});

  return {
    props: { progress: true, categories, lang, }, // will be passed to the page component as props,
  }
}
