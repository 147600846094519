import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './Flag.module.scss';

let webGLCurtain = {};

function Flag({ children, className, startGsap, id }) {
  const plane = useRef(null);

  useEffect(() => {
    return () => {
      webGLCurtain = {};
    }
  }, []);

  return (
    <div className={classNames(styles.Flag_wrapper, className)}>
      {/* <div id={"canvas_plane_" + id} className={styles.curtains_canvas}></div> */}
      <div ref={plane} className={styles.Flag}>
        {children}
      </div>
    </div >
  )
}

export default React.memo(Flag);