import classNames from 'classnames';

import React, { useState, useCallback } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Controller } from 'swiper';

import styles from './ThirdSwiper.module.scss';

import Button from '../../Button/Button';

import { useRouter } from 'next/router';

import names, { images } from '../swiper-names';

import { useTranslation } from '../../../utils/LocaleProvider';

import { setPageLoading } from '../../../store/slice';
import { useDispatch } from 'react-redux';
// import Image from 'next/future/image';


export default React.memo(
  function ThirdSwiper({ changeDir, activeIndex }) {
    const [firstSwiper, setFirstSwiper] = useState({ activeIndex });
    const router = useRouter();
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const handleBasket = useCallback(() => {
      dispatch(setPageLoading(true))
      router.push('/shop/' + names[firstSwiper.activeIndex % 3]);
    }, [firstSwiper]);

    const handleChange = sw => {
      setFirstSwiper({ activeIndex: sw.activeIndex });
      changeDir(['pink/', 'brows/', 'black/'][sw.activeIndex % 3])
    }

    const productInfo = {
      lips: ['16 оттенков','Органические пигменты \nна минеральной основе'],
      brows: ['8 оттенков',`Вы легко подберёте нужный оттенок без смешивания`],
      eyes: ['2 оттенка','Высокий остаток \nпосле первой процедуры'],
    }

    const currentProduct = names[firstSwiper?.activeIndex % 3]

    const flipHorizontally = {
      transform: 'scaleX(-1)',
    }

    return (
      <div className={styles.ThirdSwiper}>
        <Swiper
          className={styles.ThirdSwiper_Swiper}
          modules={[Navigation, Controller]}
          initialSlide={activeIndex}
          slidesPerView={3}
          centeredSlides={true}
          speed={500}
          onSlideChange={handleChange}
          navigation={
            {
              nextEl: ".swiper-button-next-third",
              prevEl: ".swiper-button-prev-third"
            }
          }
          loop={true}
        >

          {
            Object.keys(images).map((color, index) => (
              <SwiperSlide key={index} className={styles.ThirdSwiper_Slide}>
                {({ isActive, isPrev, isNext, isDuplicate }) => (
                  <>
                    {
                      Object.keys(images[color]).map((position, key) => (
                        <picture key={key}
                        >
                          <img
                            className={
                              classNames(styles[position], {
                                [styles.isActive]: isActive,
                                [styles.isPrev]: isPrev,
                                [styles.isNext]: isNext,
                                [styles.isDuplicate]: isDuplicate
                              })
                            }
                            src={`/assets/swiper/${images[color][position]}-225.webp`} />
                        </picture>

                      ))
                    }

                    <Button
                      onClick={handleBasket}
                      className={classNames(styles.ThirdSwiper_Slide_button, { [styles.ThirdSwiper_Slide_button_isActive]: isActive })}
                      title={t('select_shade', "выбрать оттенок")}
                    />
                  </>
                )}
              </SwiperSlide>
            ))
          }
        </Swiper>

        <div className={styles.ThirdSwiper_navigation}>
          <button type="button" className={classNames(styles.ThirdSwiper_navigation_button, "swiper-button-prev", 'swiper-button-prev-third')}>
            <svg width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
            </svg>
          </button>
          <span>{t('categories.' + names[firstSwiper?.activeIndex % 3])}</span>
          <button type="button" className={classNames(styles.ThirdSwiper_navigation_button, "swiper-button-next", 'swiper-button-next-third')}>
            <svg style={flipHorizontally} width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
            </svg>
          </button>
        </div>

        <div className={styles.ThirdSwiper_info}>
          {t('categories.' + currentProduct)}
          <div className={styles.ThirdSwiper_info_text}>

            {productInfo[currentProduct] && productInfo[currentProduct].map((el, index) => (
              <p key={index} className={styles.ThirdSwiper_info_text}>{el}</p>
            ))}
          </div>
        </div>
      </div>
    )
  },
  () => {
    return true
  }
);

