import classNames from "classnames";
import React from "react";

import styles from "./UniqueText.module.scss";

const uniqueText = [
  {
    first: `Гибридные пигменты`,
  },
  {
    first: "Высокая гомогенность и качество компонентов\nГарантированный остаток до 90% после процедуры  ",
  },
  {
    first: "цветовые решения с учетом\nвсех трендов коллористики beauty-индустрии",
  },
  {
    first: 'эстетика для профессионалов в каждой детали'
  }
];

function UniqueText() {
  return (
    <div className={styles.UniqueText}>
      <ul>
        {uniqueText.map((el, index) => (
          <li className={classNames(styles.h1)} key={index}>{el.first}</li>
        ))}
      </ul>
    </div>
  );
}

export default React.memo(UniqueText);
