import classNames from 'classnames';
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './FirstSwiper.module.scss';
import { Navigation, Controller } from 'swiper';
import names, { images } from '../swiper-names';
import { useTranslation } from '../../../utils/LocaleProvider';

export default React.memo(
  function FirstSwiper({ changeDir, activeIndex }) {
    const [firstSwiper, setFirstSwiper] = useState({ activeIndex });
    const { t } = useTranslation();

    const handleChange = sw => {
      setFirstSwiper({ activeIndex: sw.activeIndex });
      changeDir(['pink/', 'brows/', 'black/'][sw.activeIndex % 3])
    }

    const flipHorizontally = {
      transform: 'scaleX(-1)',
    }

    return (
      <div className={styles.FirstSwiper}>
        <Swiper
          className={styles.FirstSwiper_Swiper}
          modules={[Navigation, Controller]}
          initialSlide={activeIndex}
          slidesPerView={3}
          centeredSlides={true}
          speed={500}
          onSlideChange={handleChange}
          navigation={
            {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev"
            }
          }
          loop={true}
        >
          {
            Object.keys(images).map((color, index) => (
              <SwiperSlide key={index} className={styles.FirstSwiper_Slide}>
                {({ isActive, isPrev, isNext, isDuplicate }) => (
                  <>
                    {
                      Object.keys(images[color]).map((position, key) => (

                        <picture key={key}
                        >
                          <img
                            className={
                              classNames(styles[position], {
                                [styles.isActive]: isActive,
                                [styles.isPrev]: isPrev,
                                [styles.isNext]: isNext,
                                [styles.isDuplicate]: isDuplicate
                              })
                            }
                            src={`/assets/swiper/${images[color][position]}-60.webp`} />
                        </picture>
                      ))
                    }
                  </>
                )}
              </SwiperSlide>
            ))
          }
        </Swiper>

        <div className={styles.FirstSwiper_navigation}>
          <button type="button" className={classNames(styles.FirstSwiper_navigation_button, "swiper-button-prev")}>
            <svg width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
            </svg>
          </button>
          <span>{t('categories.' + names[firstSwiper?.activeIndex % 3])}</span>
          <button type="button" className={classNames(styles.FirstSwiper_navigation_button, "swiper-button-next")}>
            <svg style={flipHorizontally} width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 1L2 10H45" stroke="#000" strokeWidth="1.5" />
            </svg>
          </button>
        </div>

        <div className={styles.FirstSwiper_info}>{t('categories.' + names[firstSwiper?.activeIndex % 3])}</div>
      </div>
    )
  },
  () => {
    return true
  }
);

